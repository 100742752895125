.react-simple-scheduler {
  background: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', sans-serif;
  -webkit-user-select: none;
  user-select: none;

  /*
   * CALENDAR HEADER
   */
  .head {
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;

    h1 {
      width: 208px;
      margin-left: 16px;
      margin-right: 16px;
      font-family: inherit;
    }
    button {
      cursor: pointer;
      font-family: inherit;
    }
    button.today {
      width: 103px;
      height: 26px;
      background: white;
      color: black;
      border: 1px solid #bbbcbc;
      border-radius: 3px;
      font-size: 14px;
      margin-right: 28px;
    }
    button.chevron {
      background: transparent;
      border: none;
    }
    button.chevron.flipped {
      transform: scaleX(-1);
    }
    .counterweight {
      width: 103px;
    }
  }

  /*
   * CALENDAR TABLE
   */
  .body {
    height: 100%;
    overflow: auto;
    position: relative;

    .schedule {
      table-layout: fixed;
      width: 100%;
      font-family: inherit;

      th {
        font-size: 12px;
        line-height: 22px;
        font-weight: normal;
        padding-bottom: 8px;

        background: white;
        top: 0;
        position: sticky;
        z-index: 100;
      }
      th.today {
        color: rgba(64, 64, 200, 0.9);
      }
      th.today div {
        margin: 0 auto;
        padding: 0;
      }
      th.today div:last-child {
        width: 22px;
        height: 22px;
        border-radius: 11px;
        background: rgba(64, 64, 200, 0.9);
        color: #ffffff;
      }

      td {
        height: 48px;
        border-left: 1px solid #bbbcbc;
        border-top: 1px solid #bbbcbc;
      }
      td:last-child {
        border-right: 1px solid #bbbcbc;
      }
      tr:last-child td {
        border-bottom: 1px solid #bbbcbc;
      }
      td.time {
        width: 61px;
        transform: translateY(-24px);
        border: none !important;
        font-size: 12px;
        color: #bbbcbc;
        text-align: right;
        padding-right: 9px;
      }
      th.time {
        width: 61px;
      }
    }

    .event {
      position: absolute;
      background: #ccf1f0;
      color: #00bab3;
      z-index: 1;
      width: 112px;
      border-radius: 8px;
      padding: 0 6px;
      font-size: 12px;
      box-sizing: border-box;
      border: 1px solid white;
      cursor: pointer;
      overflow: hidden;

      .title {
        font-weight: 600;
      }
      .time {
        font-size: 8px;
      }
    }
    .current {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      z-index: 99 !important;
    }

    .ticker {
      position: absolute;
      z-index: 99;
      transform: translateY(-4px);

      .ball {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: rgba(64, 64, 200, 0.9);
        transform: translateX(-5px);
      }
      .line {
        height: 2px;
        background: rgba(64, 64, 200, 0.9);
        transform: translateY(-6px);
      }
    }
  }
}
