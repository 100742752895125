.react-simple-calendar {
  width: 176px;
  height: 176px;
  background: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', sans-serif;

  .head {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .month {
      font-size: 12px;
    }

    button.chevron {
      background: transparent;
      border: none;
      cursor: pointer;
    }
    button.chevron.flipped {
      transform: scaleX(-1);
    }
  }

  .body {
    width: 100%;
    height: 90%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 8px;

    th,
    td {
      text-align: center;
    }

    td {
      cursor: pointer;
    }
    td.subtle {
      color: rgba(0, 0, 0, 0.3);
    }
    td.today {
      border-radius: 16px;
      background: rgba(64, 64, 200, 0.9);
      color: #ffffff;
    }
    td.selected:not(.today) {
      border-radius: 16px;
      background: rgba(208, 208, 255, 0.733);
      color: rgb(91, 91, 255);
    }
  }
}
